<template>
    <div class="app">
        <el-dialog
                title="充值"
                :visible.sync="dialogSendVisible"
                width="20%"
                :before-close="handleSendClose"
        >
            <el-timeline>
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="充值金额" prop="amount"
                                  :rules="[{ required: true, message: '请填写充值金额', trigger: 'blur' },]">
                        <el-input-number type="number" :precision="2" :min="1" :step="100" :max="100000" placeholder="请填写充值金额" v-model="form.amount"></el-input-number>
                    </el-form-item>
                </el-form>

            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSendClose">取 消</el-button>
                <el-button type="primary" @click="confirm('form')">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "recharge",
        props: {
            account:String,
        },
        data() {
            return {
                form: {
                    account:'',
                    amount:1.00,
                },
                dialogSendVisible: true,
            };
        },
        created(){
            this.form.account = this.account
        },
        methods: {
            handleSendClose() {
                this.dialogSendVisible = false;
                this.$parent.listData();
                this.$parent.dialogRechargeVisible = false;
            },
            confirm(e) {
                this.$refs[e].validate((res) => {
                    if (res) {
                        const url = this.$api.awzgs.awzgsUserRecharge;
                        this.$post(url, this.form).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功!",
                                });
                                this.handleSendClose();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "失败!",
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });

            },
        },
    };
</script>
