<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>惠链接</el-breadcrumb-item>
                    <el-breadcrumb-item>用户查询</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="用户手机号">
                            <el-input
                                    v-model="where.account"
                                    placeholder="请输入用户手机号"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="推荐人手机号">
                            <el-input
                                    v-model="where.referAccount"
                                    placeholder="推荐人手机号"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="加入起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate" @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                                    :picker-options="pickerOptions"
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="add"
                                    icon="el-icon-plus">新增
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets">导出数据
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="username" label="用户姓名"></el-table-column>
                    <el-table-column prop="account" label="手机号"></el-table-column>
                    <el-table-column prop="referAccount" label="推荐人手机号"></el-table-column>
                    <el-table-column prop="totalCredit" label="累计入账金额"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="180px">
                        <template slot-scope="scope">
                            <el-button
                                    type="danger"
                                    class="el-icon-delete"
                                    @click="rechargeBtn(scope.row)">充值</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <recharge :account="account" v-if="dialogRechargeVisible"></recharge>
    </div>
</template>

<script>
    import { pickerOptions} from "@/plugins/function.js";
    import recharge from "@/components/admin/awzgs/user/recharge";
    export default {
        name: "admin-awzgs-order-index",
        components: {
            recharge,
        },
        data() {
            return {
                pickerOptions,
                list: [],
                date: "",
                row: {},
                loading: false,
                account:'',
                dialogRechargeVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectId: "",
                where: {
                    pageNum: 0,
                    pageSize: 20,
                    account: '',
                    referAccount: '',
                    startTime: '',
                    endTime: '',
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.$post(this.$api.awzgs.awzgsUserList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.where.pageNum = this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.where.pageSize = this.pageSize = e;
                this.listData();
            },
            changeDate(obj) {
                if (obj) {
                    this.where.startTime = obj[0];
                    this.where.endTime = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startTime = '';
                        this.where.endTime = '';
                    }
                });
            },
            exportData() {
                // console.log("exportData");
            },
            add(){
                this.$router.push({name: "awzgsUserAdd"});
            },
            rechargeBtn({account}) {
                console.log(account);
                this.account = account;
                this.dialogRechargeVisible = true;
            },
        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
</style>
